<template>
  <div>
    <div id="logo">
      <img v-if="collapsed" :src="logo_min" alt="短信管理平台" />
      <img v-else :src="logo" alt="短信管理平台" />
      <h2 v-if="!collapsed" id="logo-title">短信管理平台</h2>
    </div>
    <a-menu
      mode="inline"
      theme="dark"
      :openKeys="openKeys"
      :selectedKeys="selectedKeys"
      @openChange="onOpenChange"
      @click="onClick"
    >
      <template v-for="item in routes" :key="item.name">
        <template v-if="!item.children">
          <a-menu-item :key="item.path">
            <span
              ><SvgIcon :iconName="item.meta.iconUrl" className="svg20"
            /></span>
            <span>{{ item.meta.title }}</span>
          </a-menu-item>
        </template>
        <template v-else>
          <a-sub-menu :key="item.name">
            <template #title>
              <span class="anticon">
                <SvgIcon :iconName="item.meta.iconUrl" className="svg20" />
              </span>
              <span>{{ item.meta.title }}</span>
            </template>
            <template v-for="subitem in item.children" :key="subitem.path">
              <template v-if="!subitem.children">
                <a-menu-item :key="subitem.path">
                  <span
                    ><SvgIcon
                      :iconName="subitem.meta.iconUrl"
                      className="svg20"
                  /></span>
                  <router-link :to="subitem.path">
                    <span class="submenu-item">{{ subitem.meta.title }}</span>
                  </router-link>
                </a-menu-item>
              </template>
            </template>
          </a-sub-menu>
        </template>
      </template>
    </a-menu>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import store from "@/store";
import SvgIcon from "@/components/Svgicon";

export default defineComponent({
  name: "Aside",
  components: {
    SvgIcon,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    openKeys: {
      type: Array,
      default: () => [],
    },
    selectedKeys: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    "handleSetSelectedKeys",
    "handleSetTabs",
    "handleSetOpenKeys",
    "handleSetActiveKey",
  ],
  setup(props, context) {
    const { routes } = store.state;
    const state = reactive({
      logo: require("@/assets/images/logo.png"),
      logo_min: require("@/assets/images/logo_min.png"),
    });
    const onOpenChange = (openKeys) => {
      let v_openKeys =
        openKeys.length > 0 ? [openKeys[openKeys.length - 1]] : [];
      sessionStorage.setItem("openKeys", v_openKeys);
      context.emit("handleSetOpenKeys", v_openKeys);
    };
    const onClick = (e) => {
      state.selectedKeys = e.key;
      sessionStorage.setItem("selectedKeys", state.selectedKeys);
      store.dispatch("clickMenuItem", state.selectedKeys);
      context.emit(
        "handleSetActiveKey",
        JSON.parse(sessionStorage.getItem("activeKey"))
      );
      context.emit("handleSetSelectedKeys", [state.selectedKeys]);
      context.emit("handleSetTabs", JSON.parse(sessionStorage.getItem("tabs")));
    };
    return {
      ...toRefs(state),
      routes,
      onOpenChange,
      onClick,
    };
  },
});
</script>
<style lang="scss" scoped>
#logo {
  height: 100px;
  padding: 10px 22px 15px 22px;
  border-bottom: 1px solid rgb(70, 70, 70);
}
#logo-title {
  padding: 5px 40px 5px 40px;
  color: rgb(76, 74, 236);
}
.submenu-item {
  padding-left: 8px;
}
</style>